import React, { useState, useEffect } from 'react';
// import { DatePicker } from 'antd';
import { CustomSelect } from '../base_components/select_component';
import ListsItem from '../base_components/list_item';
import { expressCompanyRequestGet } from './custom_select_requests';

// Deliver 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = ["id", "express_company_code", "express_number"];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = [];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = ["password", "id"];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {"id": "ID", "express_company_code": "快递公司", "express_number": "快递单号", "add_time": "创建时间", "update_time": "更新时间", "explains": "说明"};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
const SelectExpressCompany = ({ value = '', onChange = e => console.log(e) }) => {
  const [selectOptions, setSelectOptions] = useState([]);
  useEffect(() => {
    expressCompanyRequestGet().then(respObj => {
        const resList = respObj.results;
        let updateOptions = [];
        for (let index = 0; index < resList.length; index++) {
          const element = resList[index];
          updateOptions.push({
            value: element.express_company_code,
            label: element.express_company_name
          })
        }
        setSelectOptions(updateOptions);
      }).catch(error => {
        console.log(error);
        setSelectOptions([]);
      });
  }, []);
  return (<CustomSelect options={selectOptions} value={value} onChange={onChange}></CustomSelect>)
};
// const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
// const SelectBoolean = (<CustomSelect options={[{ value: 0, label: '否' }, { value: 1, label: '是' }]} />);
const formKeySelectComponentsObj = {
  'express_company_code': <SelectExpressCompany />,
  // exampleColumn: SelectBoolean,
};

// ~~~~~~ 表单自定义操作按钮, 根据实际添加 ~~~~~~
const formKeysHandleButtonObj = (setOptModalState) => {
  return {
    group_purchase_commander_name: {buttonTips: '团购团长', clickMethod: () => setOptModalState(true)},
  };
};

// ~~~~~~ 表单是图片列, 根据实际添加(废弃, 改用 imageKeyNames) ~~~~~~
// const formKeysIsImages = [];

// ~~~~~~ 表单 get 请求条件key, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = [];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {"id": 0, "add_time": "创建时间", "": "", "explains": ""}

// ~~~~~~ 表单值规则, 根据实际添加 ~~~~~~
// formRulesList = {formKey: [{require: true, message: 'Please input Password!',}, ]};
const formRulesList = {"password": [{required: true, message: 'Please input Password!',}, ], }

// ~~~~~~ 图片列名称, 根据实际修改(多张图片去掉最后序号数字) ~~~~~~
const imageKeyNames = ['pic'];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 1;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['delivery-address/v1/customerdeliveryaddress', ];

// 修改 ~~~~~~~ item keys ~~~~~~;
const ItemRendererComponent = ({ item, index, itemDetailClick, selectButtonOnClick }) => {
  // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
  const [customerName, setCustomerName] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');

  useEffect(() => {
    // 显示姓名 + 微信昵称
    const customerName = item.name ? item.name : '';
    let wxNickname = '';

    const customerAvatar = item.avatar_url ? item.avatar_url : '';
    let wxAvatar = '';
    if (Object.keys(item).includes('wx_info') && Object.keys(item.wx_info).includes('wx_nickname')) {
      wxNickname = item.wx_info.wx_nickname;
      wxAvatar = item.wx_info.wx_avatar;
    }

    setCustomerName(wxNickname.length > 0 ? `${customerName}(${wxNickname})` : customerName);
    setAvatarUrl(customerAvatar.length > 0 ? customerAvatar : wxAvatar);
  }, [item]);

  const [customButtons, setCustomButtons] = useState([]);

  useEffect(() => {
    if (selectButtonOnClick) {
      const selectButtonObj = {
        buttonLabel: '选择',
        onClick: (listIndex) => {
          selectButtonOnClick(item, index);
        },
      };
      setCustomButtons([selectButtonObj,]);
    }
    
  }, [selectButtonOnClick]);

  // console.log(item);
  return (<div>
    <ListsItem
      listsIndex={index}
      url={avatarUrl}
      productTitle={customerName}
      productEffects={`手机: ${item.mobile}`}
      salePrice={0}
      clickDetailHandle={itemDetailClick}
      customButtons={customButtons}
    ></ListsItem>
  </div>);
};

export {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysHandleButtonObj,
  // formKeysIsImages,
  formGetRequestParamsKeys,
  formInitialObj,
  formRulesList,
  imageKeyNames,
  maxImagesNum,
  apiLists,
  ItemRendererComponent
};

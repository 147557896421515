import React from 'react';
import AxiosRequest from '../../apis';
import { sendAxiosRequest } from '../../apis/sendRequest';

const  apiLists = ['printer-admin/v1/defaultprinter', ];

// 打印机 request 请求

const PrinterRequestGetComponent = ({ requestCount, reqParams, responseDataHandle }) => {
    // 打印机 Get请求 获取默认打印机列表
    const api = [].concat(apiLists)[0];
    const method = 'get';
    const reqObject = {};
  
    return (
      <AxiosRequest
        responseDataHandle={responseDataHandle} api={api} method={method}
        reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
      ></AxiosRequest>
    );
};
PrinterRequestGetComponent.defaultProps = {
    requestCount: 0,
    reqParams: {},
    responseDataHandle: (respObj) => { console.log(respObj); },
};

const printerRequestPost = (reqObject) => {
    // 新建或更新默认打印机 Post请求 reqObject = {"page_name": '页面名称', "printer_name": "打印机名称"}
    return new Promise(function (resolve, reject) {
        const api = [].concat(apiLists)[0];
        const method = 'post';
        const reqParams = {};
        // const reqObject = {};
        sendAxiosRequest(api, method, reqParams, reqObject)
            .then(respObj => {
                resolve(respObj);
            })
            .catch(err => {
                console.log(err);
                reject(err);
            });
    });
};

const downloadDefaultPrinterName = (pageName) => {
    return new Promise((resolve, reject) => {
        const api = [].concat(apiLists)[0];
        const method = 'get';
        const reqParams = {};
        const reqObject = {};
        sendAxiosRequest(api, method, reqParams, reqObject).then(respObj => {
            if (Object.keys(respObj).includes('results') && respObj.results.length > 0) {
                const printersList = respObj.results;
                for (let index = 0; index < printersList.length; index++) {
                    const element = printersList[index];
                    if (element.page_name === pageName) {
                        resolve(element.printer_name);
                        return;
                    }
                }
                reject('None default printer...');
            } else {
                reject('Error network...');
            }
        }).catch(err => {
            console.log(err);
            reject(err);
        });
    });
};

export {
    PrinterRequestGetComponent,
    downloadDefaultPrinterName,
    printerRequestPost,
};

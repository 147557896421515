import React, { useRef, useState, useEffect } from 'react';
import { Modal, Divider, message } from 'antd';

// import StsUploadFile from '../upload_files/sts_upload_file';
import { StsUploadImagesList } from '../upload_files/sts_upload_files_list';
import { PurchaseRecordsRequestPut, PurchaseRecordsRequestDelete } from './purchase_records_requests';
import { CustomForm } from '../base_components/form_component';
import { CustomerOptModal } from '../modals/customer_opt';
import { DioptersOptModal } from '../modals/diopters_opt';
import PageIsLoading from '../base_components/page_is_loading';
import { customerShowName } from '../base_components/purchase_records_goods_delivery_address/purchase_records_common_funcs';

import {
    formKeys,
    formDisabledKeys,
    formNotShowKeys,
    formKeysLabelsObj,
    formKeySelectComponentsObj,
    formKeysHandleButtonObj,
    formGetRequestParamsKeys,
    formInitialObj,
    imageKeyNames,
    maxImagesNum
} from './purchase_records_columns_label_config';

// 编辑 PurchaseRecords

const UpdatePurchaseRecords = ({ visible, setVisible, oldObj, successDeletedOrUpdated }) => {
    const [pageLoading, setPageLoading] = useState(false);
    // const [oldPurchaseRecordsHomeImages, setOldPurchaseRecordsHomeImages] = useState([]);
    const purchaseRecordsImageObj = useRef({});
    const [dioptersObj, setDioptersObj] = useState({diopter_l: "", diopter_r: "", pupillary_distance: ""});

    // const [oldPurchaseRecordsImageUrl, setOldPurchaseRecordsImageUrl] = useState('');
    const [oldFormObj, setOldFormObj] = useState({});

    const [initFormCount, setInitFormCount] = useState(0);

    const [putRequestCount, setPutRequestCount] = useState(0);
    const reqObject = useRef({});
    const putResponseDataHandle = (respObj) => {
        setPageLoading(false);
        if (Object.keys(respObj).includes('code')
            && respObj.code === 1000) {
            message.success('修改成功');
            // 成功删除或更新后操作 参数 {method: 'update/delete', result: respObj}
            successDeletedOrUpdated({method: 'update', result: respObj})
            setVisible(0);
        } else {
            message.error('网络错误...');
        }
    }

    const [deleteRequestCount, setDeleteRequestCount] = useState(0);
    const deletePurchaseRecords = () => {
        // 删除当前对象
        if (Object.keys(oldObj).includes('id') && oldObj.id > 0) {
            setDeleteRequestCount(deleteRequestCount + 1);
            
            // 成功删除或更新后操作 参数 {method: 'update/delete', result: respObj}
            successDeletedOrUpdated({method: 'delete', result: null});
        } else {
            // 修改过顾客后无法删除，需要重新打开删除
            message.warn('修改顾客后不能直接删除, 关闭并重新打开再删除');
        }
    };

    const [customerOptModalState, setCustomerOptModalState] = useState(0);
    const selectCustomerHandle = (cusotomerObj) => {
        // 选择顾客操作
        console.log(cusotomerObj, cusotomerObj.name);
        let showName = cusotomerObj.name;
        if (Object.keys(cusotomerObj).includes('wx_info')) {
            showName = `${showName}(${cusotomerObj.wx_info.wx_nickname})`;
        }
        // setOldFormObj({ ...oldFormObj,
        setOldFormObj({
            customer_info: cusotomerObj,
            customer_id: cusotomerObj.id,
            customer_name: showName
        });
        setCustomerOptModalState(false);
    };

    const selectDioptersHandle = (dioptersObj) => {
        // 选择光度后操作, 保存当前光度和瞳距, 更新表单
        setDioptersObj(dioptersObj);  // 保存当前光度和瞳距
        setOldFormObj(dioptersObj);   // 更新表单
        setCustomerOptModalState(0);            // 关闭选择表单弹出框
    };

    useEffect(() => {
        if (visible > 0) {
            initImgAndForm();   // 初始化表格和图片 和光度对象
        }
    }, [visible, oldObj]);

    const initImgAndForm = () => {
        // 初始化表格和图片和光度对象
        setInitFormCount(initFormCount + 1);  // 初始化表单
        let customer_name = '';
        let hasPrintNum = 0;
        try {
            if (Object.keys(oldObj).includes('customer_info')) {
                customer_name = customerShowName(oldObj.customer_info);
            }
            hasPrintNum = oldObj.has_print ? 1 : 0;
        } catch (error) {
            console.error(error);
            console.log(oldObj);
        }
        const oldPurchaseRecord = { ...oldObj, customer_name: customer_name, has_print: hasPrintNum };
        setOldFormObj(oldPurchaseRecord);  // 给表单赋值

        // 保存光度对象
        setDioptersObj({
            diopter_r: oldPurchaseRecord.diopter_r,
            diopter_l: oldPurchaseRecord.diopter_l,
            pupillary_distance: oldPurchaseRecord.pupillary_distance,
        });
    }

    const purchaseRecordsImageUploaded = (imagesObj) => {
        // 轮播图上传后操作
        purchaseRecordsImageObj.current = imagesObj;
    }

    const onFinish = (values) => {
        // 提交表单 表单没有customer_id, 提交时需要添加
        const has_print_boolean = values.has_print === 1 ? true : false;
        let upObj = {
            ...values, ...purchaseRecordsImageObj.current,
            customer_id: oldFormObj.customer_id,
            has_print: has_print_boolean
        };
        console.log(upObj);
        setPageLoading(true);
        // 时间转时间戳
        // let newDate = new Date(upObj.end_time);
        // upObj.end_time = parseInt(newDate.getTime());    // 时间转时间戳
        // console.log(upObj.end_time);
        reqObject.current = upObj;
        setPutRequestCount(putRequestCount + 1);
    }

    return (
        <Modal visible={visible}
            title="PurchaseRecords"
            closable={false}
            footer={null}
        >
            <PageIsLoading spinning={pageLoading}></PageIsLoading>
            <div style={{ width: '100%', height: 'auto', textAlign: 'center' }}>
                <StsUploadImagesList
                    maxImagesNum={maxImagesNum}
                    imageKeyName="image_cos_name"
                    oldObj={oldObj}
                    onChange={purchaseRecordsImageUploaded}
                ></StsUploadImagesList>
            </div>
            <Divider></Divider>

            <CustomForm
                columns={formKeys}
                disabledKeys={formDisabledKeys}
                notShowKeys={formNotShowKeys}
                formKeyLabelObj={formKeysLabelsObj}
                formKeySelectComponentsObj={formKeySelectComponentsObj}
                formKeysItemButtonObj={formKeysHandleButtonObj(setCustomerOptModalState)}
                onFinish={onFinish}
                cancelHandle={() => setVisible(0)}
                showDeleteButton={true}
                deleteHandle={deletePurchaseRecords}
                updateFormObj={oldFormObj}
                formInitialObj={{}}
                initFormCount={initFormCount}
            ></CustomForm>

            <PurchaseRecordsRequestPut
                requestCount={putRequestCount}
                reqObject={reqObject.current}
                responseDataHandle={putResponseDataHandle}
                id={reqObject.current.id}></PurchaseRecordsRequestPut>
            <PurchaseRecordsRequestDelete
                requestCount={deleteRequestCount}
                responseDataHandle={(respObj) => setVisible(0)}
                id={oldObj.id}></PurchaseRecordsRequestDelete>
            <CustomerOptModal
                modalState={customerOptModalState === 1}
                setModalState={setCustomerOptModalState}
                selectCustomerHandle={selectCustomerHandle}></CustomerOptModal>
            <DioptersOptModal
            sourceDiopterObj={dioptersObj}
            modalState={customerOptModalState === 3}
            setModalState={setCustomerOptModalState}
            selectHandle={selectDioptersHandle}></DioptersOptModal>
        </Modal>
    )
}

UpdatePurchaseRecords.defaultProps = {
    visible: false,
    setVisible: (val) => console.log(val),
    oldObj: {}
}

export default UpdatePurchaseRecords;

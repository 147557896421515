import React, { useEffect, useState } from 'react';
// 滚动条记住当前位置，并回到当前位置

const computeCurrentScrollTop = () => {
    // 计算当前滚动条高度
    if (typeof document !== 'undefined') {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop; //滚动的高度;
        // const clientHeight = document.documentElement.clientHeight || document.body.clientHeight; // 浏览器视口的高度;
        // const pageHeight = document.body.scrollHeight || document.documentElement.scrollHeight || document.documentElement.offsetHeight; // 文档的总高度
        return scrollTop;
    }
    return 0
}

const scrollToTopSize = (topSize) => {
    // 滚动条滚动到指定位置
    if (typeof window !== 'undefined') {
        setTimeout(() => {
            // console.log(topSize);
            // console.log('body');
            window.scrollTo({ 
                top: parseInt(topSize), 
                behavior: "smooth" 
            });
        }, 500)
    }
}

const ScrollGoBack = ({visible}) => {
    // visible > 0 滚动条记住当前位置，visible == 0 返回当前位置
    const [currentScrollTop, setCurrentScrollTop] = useState(0);

    useEffect(() => {
        if (visible > 0) {
            // 保存当前位置
            const currentTop = computeCurrentScrollTop();
            // console.log(currentTop);
            setCurrentScrollTop(currentTop);
        }
        if (visible === 0) {
            // 滚动条回到原来位置
            scrollToTopSize(currentScrollTop);
        }
    }, [visible])
    return <></>
}
ScrollGoBack.defaultProps = {
    visible: 0
}

export { ScrollGoBack };
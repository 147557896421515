import React, { useState, useRef } from 'react';
// import { navigate, Link } from 'gatsby';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';

import { ChinaAreaSelect } from './deliver_area_select';
import UpdateDeliver from './update_deliver';
// Deliver 入口组件

const DeliverComponent = ({ selectButtonOnClick = null, purchaseRecordId = 0, customerId = 0 }) => {
  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);
  const [visible, setVisible] = useState(false);
  const [oldObj, setOldObj] = useState({});

  const [showEditDeliver, setShowEditDeliver] = useState(false);

  const queryHandle = (currentRequestParams) => {
    // 查询操作
    reqParams.current = { ...currentRequestParams };
    // 触发重新读取数据
    setReloadListCount(reloadListCount + 1)
    // console.log('search...');
  };

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  const selectedAreaHandle = selectedArea => {
    // 选择地区后操作
    console.log(selectedArea);
  }

  // const selectButtonOnClick = (item, index) => {
  // 列表选择按钮点击事件
  //   console.log(index, item);
  // }

  return (
    <div>
      <ChinaAreaSelect selectedAreaHandle={selectedAreaHandle} />
      <Button onClick={() => setVisible(true)} type="link">发货</Button>
      {visible ? <UpdateDeliver
        visible={visible}
        setVisible={setVisible}
        oldObj={oldObj}
        purchaseRecordId={purchaseRecordId}
        customerId={customerId}
      ></UpdateDeliver> : null}
      
    </div>
  )
};

export default DeliverComponent;

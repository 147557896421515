import React, { useEffect, useRef, useState } from 'react';
import { message, Select, Button, Space, Tooltip } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

import { loadPrinter } from './load_printer';
// import { CustomSelect } from '../select_component';
import customConfig from '../../../custom_config';
import { appendMetaContentSecurityPolicy } from '../../../assets/common_funcs/meta_handle.js';

import { downloadDefaultPrinterName, printerRequestPost } from './printer_requests';
import { printMultiplePurchaseRecordsHandle } from '../print_purchase_record/purchase_record_print';
const { Option } = Select

const printerNameQueryIndex = (printersList, printerName) => {
    // 打印机名称查找打印机序号
    // console.log(printersList, printerName);
    for (let index = 0; index < printersList.length; index++) {
        const element = printersList[index];
        if (element.label === printerName) {
            return element.value;
        }
    }
    return null
};

const computeNotPrintRecords = purchaseRecordsList => {
    // 返回未打印订单
    const notPrintRecordsList = [];
    for (let index = 0; index < purchaseRecordsList.length; index++) {
        const element = purchaseRecordsList[index];
        // console.log(element.has_print);
        if (!element.has_print) {
            notPrintRecordsList.push(element);
        }
    }
    console.log(notPrintRecordsList);
    return notPrintRecordsList;
}

// 选择打印机组件, 批量打印未打印订单
const SelectPrinterComponent = ({ onChange, purchaseRecordsList, hasAllPrintCount, setHasAllPrintCount }) => {
    const [selectedPrinterIndex, setSelectedPrinterIndex] = useState(null);
    const selectedPrinterName = useRef('');
    const [printers, setPrinters] = useState([]);

    // 未打印订单
    const [notPrintRecords, setNotPrintRecords] = useState([]);

    // default printer
    // const [defaultPrinterName, setDefaultPrinterName] = useState('');

    const loadPrintersListHandle = () => {
        // 获取打印机列表
        // 页面中加入 meta 标签, 解决 HTTPS页面里动态的引入HTTP资源，被直接block掉
        appendMetaContentSecurityPolicy();
        loadPrinter().then(async results => {
            let printersOptions = [];
            // console.log(typeof results);
            for (let index = 0; index < results.length; index++) {
                printersOptions.push({
                    label: results[index],
                    value: index
                });
            }
            // console.log(printersOptions);
            setPrinters(printersOptions);
            message.info('打印机列表刷新成功!')
            // console.log(printersOptions);
        }).catch(err => {
            console.log(err);
            setPrinters([]);
            // message.error('获取打印列表失败...');
        });
    }

    useEffect(() => {
        // 获取打印机列表
        if (typeof document !== 'undefined' && document.readyState === 'complete') {
            loadPrintersListHandle();
        }else {
            message.info('请网页加载完成后刷新打印机!!!');
        }
    }, []);

    useEffect(() => {
        // 打印机列表改变，更新打印机为默认打印机
        const currentPageName = customConfig.pagesName.purchaseRecords;
        downloadDefaultPrinterName(currentPageName).then(loadDefaultPrinter => {
            // console.log(loadDefaultPrinter);
            // setDefaultPrinter(loadDefaultPrinter);
            if (loadDefaultPrinter && printers.length > 0) {
                selectedPrinterHandle(printerNameQueryIndex(printers, loadDefaultPrinter));
            }
        }).catch(err => console.log(err));
    }, [printers]);

    useEffect(() => {
        // 计算未打印订单
        setNotPrintRecords(computeNotPrintRecords(purchaseRecordsList));
    }, [purchaseRecordsList]);

    const selectedPrinterHandle = (selectedIndex) => {
        console.log(selectedIndex);
        setSelectedPrinterIndex(selectedIndex);
        if (printers.length > selectedIndex
            && printers[selectedIndex] instanceof Object
            && Object.keys(printers[selectedIndex]).includes('label')
        ) {
            selectedPrinterName.current = printers[selectedIndex].label;
        }
        onChange(selectedIndex);
    }

    const updateHasPrint = hasPrintRecordsList => {
        // 客户端更新订单列表为已打印
        setHasAllPrintCount(hasAllPrintCount + 1);
    }

    const printNotPrintRecords = () => {
        // 批量打印未打印订单
        const saveDefaultPrinter = {
            "page_name": customConfig.pagesName.purchaseRecords,
            "printer_name": selectedPrinterName.current
        }
        // console.log(saveDefaultPrinter);
        printerRequestPost(saveDefaultPrinter).then(result => {
            // 先保存当前打印机是默认打印机
            console.log(result);
        }).catch(err => {
            console.log(err);
        });
        // console.log(notPrintRecords);
        printMultiplePurchaseRecordsHandle(notPrintRecords, selectedPrinterIndex, updateHasPrint);
    }

    return (
        <div style={{ width: '100%', height: 'auto', margin: '10px 2px' }}>
            {/* <CustomSelect options={printers} onChange={selectedPrinterHandle} value={selectedPrinterIndex}></CustomSelect> */}
            <Space>
                <Select
                    placeholder="请选择打印机"
                    onChange={selectedPrinterHandle}
                    value={selectedPrinterIndex}
                    style={{ minWidth: 120, maxWidth: 220 }}
                >
                    {printers.map((obj, i) => {
                        return <Option key={i.toString()} value={obj.value}>{obj.label}</Option>
                    })}
                </Select>
                <Button disabled={notPrintRecords.length < 1} onClick={printNotPrintRecords} type="primary">打印(未打印)</Button>
                <Tooltip title="刷新打印机">
                    <Button shape="circle" icon={<RedoOutlined />} onClick={loadPrintersListHandle} />
                </Tooltip>
            </Space>
        </div>
    )
}
SelectPrinterComponent.defaultProps = {
    onChange: val => console.log(val),
    purchaseRecordsList: [],    // 购买订单列表，打印未打印订单用
    hasAllPrintCount: 0,
    setHasAllPrintCount: val => console.log(val),
}

export { SelectPrinterComponent };

import React, { useEffect, useRef, useState } from 'react';
import { Select, Input, Button, Form, InputNumber, Radio, Divider, Space, Modal } from 'antd';
import customConfig from '../../../custom_config';

const { Option } = Select;

const formSelectStyle = {width: 180};

const diopterSignsObj = {
    // 球镜柱镜光度符号
    1: ['negative', 'negative'],
    2: ['negative', 'positive'],
    3: ['positive', 'negative'],
    4: ['positive', 'positive'],
};

// 选择左右眼光度

const splitDiopters = (diopterStr) => {
    // 拆分光度为球镜、柱镜、轴位、ADD 返回: {spy: '', cyl: '', axi: 'int', add: ''}
    let sph=null, cyl=null, axi=null, add=null;
    if (!diopterStr) {
        return {sph, cyl, axi, add};
    }

    let delSpaceDiopter = diopterStr.replace(/(^\s*)|(\s*$)/g, "");     // 先去掉开始和结尾的空格
    if (delSpaceDiopter.indexOf('ADD') > 1) {
        // 有ADD
        const splitAddArr = delSpaceDiopter.split('ADD');
        add = splitAddArr[1].replace(/(^\s*)|(\s*$)/g, "").replace(':', '');
        delSpaceDiopter = splitAddArr[0].replace(/(^\s*)|(\s*$)/g, "");
    }

    if (delSpaceDiopter.length > 0) {
        if (delSpaceDiopter.indexOf('-', 4) > 5) {
            // 有散光
            // console.log(delSpaceDiopter);
            sph = delSpaceDiopter.slice(0, delSpaceDiopter.indexOf('-', 4)).replace(/(^\s*)|(\s*$)/g, "");
            const cylAndAxiArr = delSpaceDiopter.slice(delSpaceDiopter.indexOf('-', 4)).split('*');
            cyl = cylAndAxiArr[0].replace(/(^\s*)|(\s*$)/g, "");
            axi = parseInt(cylAndAxiArr[1].replace(/(^\s*)|(\s*$)/g, ""));
        } else {
            // 没有散光
            // console.log(delSpaceDiopter);
            sph = delSpaceDiopter.replace(/(^\s*)|(\s*$)/g, "");
        }
    }
    return {sph, cyl, axi, add};
}

const judgeDiopterCreaterThanZero = (diopter) => {
    // 判断光度的绝对值是否打大于0
    return (diopter && Math.abs(parseFloat(diopter)) > 0);
}

const createDioptersObj = (dioptersValues) => {
    // 创建左右眼光度 {r: '-0.00 -0.50 * 180 ADD:+1.50', l: '-0.00 -0.50 * 180 ADD:+1.50', pd: 60}
    let rDiopterStr = !dioptersValues.sphR ? '' : dioptersValues.sphR;
    let lDiopterStr = !dioptersValues.sphL ? '' : dioptersValues.sphL;
    const LackArgumentsObj = judgeLackArguments(dioptersValues);    // 判断是否缺少参数
    if (judgeDiopterCreaterThanZero(dioptersValues.cylR)) {
        // 右眼有散光
        if (LackArgumentsObj.lackAxiR === 0) {
            // 有轴位才添加散光
            rDiopterStr = rDiopterStr === '' ? '-0.00' : rDiopterStr;
            rDiopterStr = `${rDiopterStr} ${dioptersValues.cylR} * ${dioptersValues.axiR}`;
        }
    }
    if (judgeDiopterCreaterThanZero(dioptersValues.cylL)) {
        // 左眼有散光
        if (LackArgumentsObj.lackAxiR === 0) {
            // 有轴位才添加散光
            lDiopterStr = lDiopterStr === '' ? '-0.00' : lDiopterStr;
            lDiopterStr = `${lDiopterStr} ${dioptersValues.cylL} * ${dioptersValues.axiL}`;
        }
    }
    if (judgeDiopterCreaterThanZero(dioptersValues.add)) {
        // 有 ADD
        rDiopterStr = `${rDiopterStr} ADD:${dioptersValues.add}`;
        lDiopterStr = `${lDiopterStr} ADD:${dioptersValues.add}`;
    }
    return {diopter_r: rDiopterStr, diopter_l: lDiopterStr, pupillary_distance: dioptersValues.pd};
}

const judgeLackArguments = (dioptersValues) => {
    // 判断是否缺少瞳距或散光轴位
    // value: 0-不缺, 1-缺少 {lackPd: 缺少瞳距, lackAxiR: R缺少轴位, lackAxiL: L缺少轴位}
    const lackObj = {lackPd: 0, lackAxiR: 0, lackAxiL: 0};
    if (dioptersValues.pd.toString().length < 2) {
        lackObj.lackPd = 1;
    }

    const rAxi = dioptersValues.axiR;
    const lAxi = dioptersValues.axiL;

    if (dioptersValues.cylR && Math.abs(parseFloat(dioptersValues.cylR)) > 0) {
        // 右眼有散光
        if (rAxi) {
            if(rAxi === "" || rAxi == null){
                lackObj.lackAxiR = 1;
            }
            if (isNaN(rAxi)) {
                lackObj.lackAxiR = 1;
            }
        } else {
            lackObj.lackAxiR = 1;
        }
    }
    if (dioptersValues.cylL && Math.abs(parseFloat(dioptersValues.cylL)) > 0) {
        // 左眼有散光
        if (lAxi) {
            if(lAxi === "" || lAxi == null){
                lackObj.lackAxiL = 1;
            }
            if (isNaN(lAxi)) {
                lackObj.lackAxiL = 1;
            }
        } else {
            lackObj.lackAxiL = 1;
        }
    }
    return lackObj;
}

const dioptersList = (positiveNegative = 'negative', maxDiopters = 90) => {
    // 返回光度对象列表 [{label: '-0.00', value: '-0.00'}, {label: '-0.25', value: '-0.25'}]
    // maxDiopters 最大光度 25-散光(至600度), 17-ADD(至400度)
    // positiveNegative: negative='-', positive='+'
    const diopters = [];
    const pNSign = positiveNegative === 'negative' ? '-' : '+';
    for (let index = 0; index < maxDiopters; index++) {
        const element = pNSign + (index * 0.25).toFixed(2);
        diopters.push({label: element, value: element});
    }
    return diopters;
}

const DioptersSelectComponent = ({ value, onChange,  selectType=0, positiveNegative = 'negative', placeholder='请选择球镜' }) => {
    // 选择光度的自定义组件 selectType: 0-球镜, 1-柱镜, 2-ADD下加光
    const maxDioptersList = [90, 25, 17];
    const selectOptions = dioptersList(positiveNegative, maxDioptersList[selectType])
    return (
        <Select style={formSelectStyle} value={value} onChange={onChange} placeholder={placeholder}>
            {selectOptions.map(item => {
                return (<Option key={item.value} value={item.value}>{item.label}</Option>)
            })}
        </Select>
    )
}

const lackArgumentsTips = [
    '取消 重新输入, 确认 直接提交!',
    '取消 重新输入, 确认 直接提交!(删除散光光度)',
];

const lackArgumentsTitles = ['未输入瞳距', '未输入散光轴位', '未输入瞳距和散光轴位'];

const DoubleEyeSelectDiopter = ({sourceDiopterObj, selectedHandle, cancelHandle}) => {
    // 球镜(spherical mirror), 柱镜(cylinder mirror), 轴向位置(Axial position)
    const [form] = Form.useForm();

    const [lackArgumentsVisible, setLackArgumentsVisible] = useState(false);

    const [selectedSign, setSelectedSign] = useState(1);
    const [sphSign, setSphSign] = useState('negative');
    const [cylSign, setCylSign] = useState('negative');

    const [warnTip, setWarnTip] = useState(lackArgumentsTips[0]);
    const [warnTitle, setWarnTitle] = useState(lackArgumentsTitles[0]);

    const [hasAddItem, setHasAddItem] = useState(false);

    const submitObj = useRef({diopter_l: "", diopter_r: "", pupillary_distance: ""});

    useEffect(() => {
        // 初始化表单内容
        setHasAddItem(false);
        const dioptersObjR = splitDiopters(sourceDiopterObj.diopter_r);
        const dioptersObjL = splitDiopters(sourceDiopterObj.diopter_l);
        const add = dioptersObjR.add ? dioptersObjR.add : dioptersObjL.add;
        if (add) {
            setHasAddItem(true);
        }
        const initObj = {
            sphR: dioptersObjR.sph,
            cylR: dioptersObjR.cyl,
            axiR: dioptersObjR.axi,
            sphL: dioptersObjL.sph,
            cylL: dioptersObjL.cyl,
            axiL: dioptersObjL.axi,
            add: add,
            pd: sourceDiopterObj.pupillary_distance,
        }
        // setInitFormObj(initObj);
        // console.log(initObj);
        form.setFieldsValue(initObj);
    }, [sourceDiopterObj]);

    const onChangeSign = e => {
        // 更新度数符号
        console.log(e.target.value);
        setSelectedSign(e.target.value);
        const selectedDiopterSign = diopterSignsObj[e.target.value];
        setSphSign(selectedDiopterSign[0]);
        setCylSign(selectedDiopterSign[1]);
    }

    const onFinish = values => {
        // console.log(values);
        submitObj.current = createDioptersObj(values);
        // console.log(submitObj.current);
        const lackArgumentsObj = judgeLackArguments(values);    // 判断是否缺少参数
        let tipsNum = -1;
        if (lackArgumentsObj.lackPd > 0 || lackArgumentsObj.lackAxiR > 0 || lackArgumentsObj.lackAxiL > 0) {
            // 有错误,跳出提示框
            let lackPd = lackArgumentsObj.lackPd > 0 ? true : false;
            let lackAxiR = lackArgumentsObj.lackAxiR > 0 ? true : false;
            let lackAxiL = lackArgumentsObj.lackAxiL > 0 ? true : false;
            tipsNum = lackPd ? 0 : tipsNum;
            if (tipsNum > -1) {
                tipsNum = lackAxiR || lackAxiL ? 2 : tipsNum;
            } else {
                tipsNum = lackAxiR || lackAxiL ? 1 : tipsNum;
            }
            if (tipsNum > -1) {
                setWarnTitle(lackArgumentsTitles[tipsNum]);
                setWarnTip(tipsNum === 0 ? lackArgumentsTips[0] : lackArgumentsTips[1]);
                setLackArgumentsVisible(true);
            }
        } else {
            // 没有错误直接提交
            selectedHandle(submitObj.current);
        }
    }

    const lackArgumentsSubmit = () => {
        // 缺少散光轴位，或瞳距 继续提交光度
        setLackArgumentsVisible(false);
        selectedHandle(submitObj.current);
    }

    const addItem = (
        <Form.Item label="ADD" name="add">
            <DioptersSelectComponent selectType={2} positiveNegative="positive" placeholder="请选择ADD"></DioptersSelectComponent>
        </Form.Item>
    );

    const showAddItemButtonItem = (
        <Form.Item><Button type="link" onClick={() => setHasAddItem(true)}>ADD...</Button></Form.Item>
    )

    return (
        <div>
            <h6>正负光度: &nbsp;
                <Radio.Group onChange={onChangeSign} value={selectedSign}>
                    <Radio value={1}>-/-</Radio>
                    <Radio value={2}>-/+</Radio>
                    <Radio value={3}>+/-</Radio>
                    <Radio value={4}>+/+</Radio>
                </Radio.Group>
            </h6>
            <Divider></Divider>
            <Form form={form} name="diopters-form" onFinish={onFinish} {...customConfig.formCommonStyle.layoutForm}>
                <Form.Item label="光度R">
                    <Form.Item name="sphR">
                        <DioptersSelectComponent selectType={0} positiveNegative={sphSign}></DioptersSelectComponent>
                    </Form.Item>
                    <Form.Item name="cylR">
                        <DioptersSelectComponent selectType={1} positiveNegative={cylSign} placeholder="请选择柱镜"></DioptersSelectComponent>
                    </Form.Item>
                    <Form.Item name="axiR">
                        <InputNumber min={1} max={180} style={{width: '8rem'}} placeholder="请输入轴位"></InputNumber>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="光度L">
                    <Form.Item name="sphL">
                        <DioptersSelectComponent selectType={0} positiveNegative={sphSign}></DioptersSelectComponent>
                    </Form.Item>
                    <Form.Item name="cylL">
                        <DioptersSelectComponent selectType={1} positiveNegative={cylSign} placeholder="请选择柱镜"></DioptersSelectComponent>
                    </Form.Item>
                    <Form.Item name="axiL">
                        <InputNumber min={1} max={180} style={{width: '8rem'}} placeholder="请输入轴位"></InputNumber>
                    </Form.Item>
                </Form.Item>
                <Form.Item label="PD" name="pd">
                    <Input placeholder="请输入瞳距" style={{maxWidth: '10rem'}}></Input>
                </Form.Item>
                {/* <Form.Item label="ADD" name="add">
                    <DioptersSelectComponent selectType={2} positiveNegative="positive" placeholder="请选择ADD"></DioptersSelectComponent>
                </Form.Item> */}
                {hasAddItem ? addItem : showAddItemButtonItem}

                <Form.Item {...customConfig.formCommonStyle.tailFormItemLayout}>
                    <Space>
                        <Button type="primary" htmlType="submit">确定</Button>
                        <Button htmlType="button" onClick={cancelHandle}>取消</Button>
                    </Space>
                </Form.Item>
            </Form>

            <Modal
                title={warnTitle}
                visible={lackArgumentsVisible}
                onOk={lackArgumentsSubmit}
                onCancel={() => setLackArgumentsVisible(false)}
                okText="确认"
                cancelText="取消"
            >
                <p>{warnTip}</p>
            </Modal>
        </div>
    );
};
DoubleEyeSelectDiopter.defaultProps = {
    sourceDiopterObj: {diopter_l: "", diopter_r: "", pupillary_distance: ""},
    selectedHandle: selectedDiopterObj => console.log(selectedDiopterObj),
    cancelHandle: () => console.log('Cancel select diopters...'),
}

export { DoubleEyeSelectDiopter };

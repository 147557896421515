import React from 'react';
import { Button, Modal } from 'antd';
// import CustomerList from '../customer_list';
import CustomersComponent from '../customers';

// 选择顾客模态框

const CustomerOptModal = ({ modalState, setModalState, selectCustomerHandle, clickCreatedCustomerHandle }) => {
    // 选择顾客 modal
    return (
        <>
            <Modal
                visible={modalState}
                title="选择顾客"
                closable={false}
                footer={[
                    <Button key="back" onClick={() => { setModalState(false); }}>取消</Button>
                ]}
            // footer={null}
            >
                {/* <CustomerList
                    selectCustomerHandle={selectCustomerHandle}
                    clickCreatedCustomerHandle={clickCreatedCustomerHandle}
                ></CustomerList> */}
                <CustomersComponent selectButtonOnClick={selectCustomerHandle}></CustomersComponent>
            </Modal>
        </>
    );
};
CustomerOptModal.defaultProps = {
    modalState: false,
    setModalState: booleanValue => console.log(booleanValue),
    selectCustomerHandle: (customerObj) => { console.log(customerObj); },
    clickCreatedCustomerHandle: (createdCustomerResponseObj) => { console.log(createdCustomerResponseObj) },
};

export { CustomerOptModal }

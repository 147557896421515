import React, { useState, useRef, useEffect } from 'react';
// import { navigate, Link } from 'gatsby';
import { Button, Row, Col, Space, Tooltip } from 'antd';
import { ReloadOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';

// 打印机组件
import { SelectPrinterComponent } from '../base_components/lodop/select_printer_component';

import CreatePurchaseRecords from './create_purchase_records';
import PurchaseRecordsQueryDrawerForm from './purchase_records_query_form';
import PurchaseRecordsList from './purchase_records_list';
import PurchaseRecordsQueryParams from './purchase_records_query_params_display';
import { downloadAndSaveExpressList } from '../../assets/common_funcs/express_code_name_handle';
// PurchaseRecords 入口组件
import { UploadTaobaoCsvFilesModal } from '../modals/upload_taobao_csv';

const PurchaseRecordsComponent = () => {
  const [purchaseRecordsData, setPurchaseRecordsData] = useState([]);
  const [printerIndex, setPrinterIndex] = useState(-1);

  const reqParams = useRef({ page: 0 });

  const [reloadListCount, setReloadListCount] = useState(1);

  const [drawerVisible, setDrawerVisible] = useState(false);

  const [createPurchaseRecordsVisible, setCreatePurchaseRecordsVisible] = useState(false);

  const [hasAllPrintCount, setHasAllPrintCount] = useState(0);

  const [visible, setVisible] = useState(0);

  useEffect(() => {
    // 下载并保存快递公司列表到本地
    downloadAndSaveExpressList();
  }, []);
  
  const queryHandle = (currentRequestParams) => {
    // 查询操作
    reqParams.current = { ...currentRequestParams };
    console.log(reqParams.current);
    // 触发重新读取数据
    setReloadListCount(reloadListCount + 1)
    console.log('search...');
  };

  const initReloadList = () => {
    // 刷新列表, 删除所有查询条件
    reqParams.current = { page: 0 };
    setReloadListCount(reloadListCount + 1);
  }

  const purchaseRecordsChange = (records) => {
    // 列表组件传过来的订单列表
    setPurchaseRecordsData(records);
  }

  return (
    <div>
      <Row>
        <Col span={24}>
          <SelectPrinterComponent
          onChange={setPrinterIndex}
          purchaseRecordsList={purchaseRecordsData}
          setPurchaseRecordsList={setPurchaseRecordsData}
          hasAllPrintCount={hasAllPrintCount}
          setHasAllPrintCount={setHasAllPrintCount}
        ></SelectPrinterComponent></Col>
      </Row>
      <Row>
        <Col span={14}>
          <Space>
            {/* <Tooltip title="返回">
              <Link to="/logistic"><LeftOutlined /></Link>
            </Tooltip> */}
            <h4>PurchaseRecords</h4>
            <Tooltip title="刷新">
              <Button onClick={initReloadList} shape="circle" icon={<ReloadOutlined />}></Button>
            </Tooltip>
            <Tooltip title="搜索">
              <Button onClick={() => setDrawerVisible(true)} shape="circle" icon={<SearchOutlined />}></Button>
            </Tooltip>
          </Space>
        </Col>
        <Col span={10}>
          <div style={{ width: '100%', height: 'auto' }}>
            <Button style={{ float: 'right' }} type="link" onClick={() => setVisible(1)}>淘宝csv文件</Button>
            <Button style={{ float: 'right' }} type="link" onClick={() => setCreatePurchaseRecordsVisible(true)}>新建</Button>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}><PurchaseRecordsQueryParams paramsObj={reqParams.current}></PurchaseRecordsQueryParams></Col>
      </Row>
      <PurchaseRecordsQueryDrawerForm
        visible={drawerVisible}
        setVisible={setDrawerVisible}
        queryHandle={queryHandle}
      ></PurchaseRecordsQueryDrawerForm>

      <PurchaseRecordsList
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
        requestParams={reqParams.current}
        purchaseRecordsChange={purchaseRecordsChange}
        printerIndex={printerIndex}
        hasAllPrintCount={hasAllPrintCount}
      ></PurchaseRecordsList>

      <CreatePurchaseRecords
        visible={createPurchaseRecordsVisible}
        setVisible={setCreatePurchaseRecordsVisible}
        reloadListCount={reloadListCount}
        setReloadListCount={setReloadListCount}
      ></CreatePurchaseRecords>

      <UploadTaobaoCsvFilesModal
        visible={visible}
        setVisible={setVisible}
        visibleNum={1}
        uploadedHandle={initReloadList}
      ></UploadTaobaoCsvFilesModal>
    </div>
  )
};

export default PurchaseRecordsComponent;

import React, { useState } from 'react';
import { Drawer } from 'antd';

import { CustomForm } from '../base_components/form_component';
import { CustomerOptModal } from '../modals/customer_opt';

import {
    formKeysLabelsObj,
    // formDisabledKeys,
    formKeySelectComponentsObj,
    formKeysHandleButtonObj,
    formGetRequestParamsKeys,
    // requestParamsKeys,
    formInitialObj
} from './purchase_records_columns_label_config';
import { useRef } from 'react';
// 列表查询 form

const queryFormDisabledKeys = ['customer_name'];

const PurchaseRecordsQueryDrawerForm = ({ visible, setVisible, queryHandle }) => {
    const choseCustomerId = useRef(0);
    const [initFormCount, setInitFormCount] = useState(0);
    const [updateFormObj, setUpdateFormObj] = useState({});

    const [customerOptModalState, setCustomerOptModalState] = useState(false);
    const selectCustomerHandle = (cusotomerObj) => {
        // 选择顾客操作
        console.log(cusotomerObj, cusotomerObj.name);
        // 当前选中的顾客 id
        choseCustomerId.current = cusotomerObj.id;

        let showName = cusotomerObj.name;
        if (Object.keys(cusotomerObj).includes('wx_info')) {
          showName = `${showName}(${cusotomerObj.wx_info.wx_nickname})`;
        }
        setUpdateFormObj({ customer_name: showName });
        setCustomerOptModalState(false);
    };

    const closeDrawer = () => {
        // 关闭查询抽屉
        setInitFormCount(initFormCount + 1);
        setVisible(false);
    };

    const onFinish = (formObj) => {
        // 提交查询 根据具体情况更新 fromObj
        // ~~~~~~~ 具体情况更新 fromObj ~~~~~~
        // requestParamsKeys = ['customer_id', 'sale_platform', 'order_state', 'add_time_start', 'add_time_end', 'has_print'];
        let copyFormObj = { ...formObj };
        let queryParamsObj = {};
        const queryId = formObj.id ? parseInt(formObj.id) : 0;
        if (queryId > 0) {
            // console.log(queryId);
            queryParamsObj.id = queryId;
        }else {
            if (choseCustomerId.current > 0) {
                queryParamsObj.customer_id = choseCustomerId.current;
            }
            const sale_platform = copyFormObj.sale_platform;
            if (sale_platform > 0 && sale_platform < 6) {
                queryParamsObj.sale_platform = sale_platform;
            }
            const order_state = copyFormObj.order_state
            if (order_state > 0 && order_state < 5) {
                queryParamsObj.order_state = order_state;
            }
            // 查询日期区间
            const rangeDates = copyFormObj.range_add_time;
            if (Array.isArray(rangeDates) && rangeDates.length === 2) {
                queryParamsObj.add_time_start = rangeDates[0].format('YYYY-MM-DD');
                queryParamsObj.add_time_end = rangeDates[1].format('YYYY-MM-DD');
            }
            // const
            const has_print = copyFormObj.has_print;
            if (has_print === 0 || has_print === 1) {
                queryParamsObj.has_print = has_print;
            }
        }
        queryHandle(queryParamsObj);
        setTimeout(() => {
            closeDrawer();
        }, 50);
    };

    return (
        <>
            <Drawer
                title="销售记录查询"
                width="100%"
                height="400px"
                placement="top"
                onClose={closeDrawer}
                visible={visible}
                bodyStyle={{ paddingBottom: 80 }}
                footer={null}
            >
                <CustomForm
                    columns={formGetRequestParamsKeys}
                    disabledKeys={queryFormDisabledKeys}
                    formKeyLabelObj={formKeysLabelsObj}
                    formKeySelectComponentsObj={formKeySelectComponentsObj}
                    formKeysItemButtonObj={formKeysHandleButtonObj(setCustomerOptModalState)}
                    onFinish={onFinish}
                    cancelHandle={() => setVisible(false)}
                    showDeleteButton={false}
                    formInitialObj={formInitialObj}
                    initFormCount={initFormCount}
                    updateFormObj={updateFormObj}
                ></CustomForm>
            </Drawer>
            <CustomerOptModal
                modalState={customerOptModalState}
                setModalState={setCustomerOptModalState}
                selectCustomerHandle={selectCustomerHandle}></CustomerOptModal>
        </>
    );
};

PurchaseRecordsQueryDrawerForm.defaultProps = {
    visible: false,
    setVisible: (booleanValue) => { console.log(booleanValue); },
    queryHandle: (queryParamsObj) => { console.log(queryParamsObj); }
};

export default PurchaseRecordsQueryDrawerForm;

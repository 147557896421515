import React from 'react';
import { DatePicker } from 'antd';
import { CustomSelect } from '../base_components/select_component';
import ListsItem from '../base_components/list_item';

// import { expressCompanyRequestGet } from './custom_select_requests';

const { RangePicker } = DatePicker;

// PurchaseRecords 列 配置

// ~~~~~~ 表单列, 需添加 ~~~~~~
const formKeys = [
  "id", "input_staff_id", "latest_modify_staff_id", "customer_id", "customer_name",
  // "group_purchase_commander_id", "group_purchase_commander_name",
  "product_title", "diopter_r", "diopter_l",
  "pupillary_distance", "receive_address", "products_quantity", "purchase_amount",
  "products_cost", "sale_platform", "order_state", "pay_table_id", "pay_platform",
  "add_time", "update_time", "explains", "explains_customer", "has_print",
  "image_cos_name1", "image_cos_name2", "image_cos_name3", "image_cos_name4", "image_cos_name5"
];

// ~~~~~~ 表单不能编辑列, 根据实际添加 ~~~~~~
const formDisabledKeys = [
  "id", "input_staff_id", "latest_modify_staff_id", "customer_name", "group_purchase_commander_id", "group_purchase_commander_name", "diopter_r", "diopter_l",
  "pupillary_distance", "receive_address", "pay_table_id", "add_time", "update_time", "has_print", "image_cos_name1", "image_cos_name2",
  "image_cos_name3", "image_cos_name4", "image_cos_name5"
];

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = [
  "input_staff_id", "latest_modify_staff_id", "customer_id", "group_purchase_commander_id", "group_purchase_commander_name",
  "receive_address", "image_cos_name1", "image_cos_name2", "image_cos_name3", "image_cos_name4", "image_cos_name5"
];

// ~~~~~~ 表单列名称 label, 需添加 ~~~~~~
const formKeysLabelsObj = {
  "id": "订单ID", "input_staff_id": "订单创建管理员", "latest_modify_staff_id": "最后修改管理员", "customer_id": "顾客ID", "customer_name": "顾客",
  "group_purchase_commander_id": "团购团长ID", "group_purchase_commander_name": "团购团长", "product_title": "商品标题", "diopter_r": "光度R", "diopter_l": "光度L", "pupillary_distance": "瞳距(PD)",
  "receive_address": "收货地址", "products_quantity": "商品数量", "purchase_amount": "订单金额", "products_cost": "商品成本", "sale_platform": "销售平台",
  "order_state": "订单状态", "pay_table_id": "支付表ID", "pay_platform": "支付方式", "add_time": "新建时间", "update_time": "更新时间", "explains": "商家备注",
  "explains_customer": "顾客留言", "has_print": "打印状态", "image_cos_name1": "订单图片1", "image_cos_name2": "订单图片2",
  "image_cos_name3": "订单图片3", "image_cos_name4": "订单图片4", "image_cos_name5": "订单图片5",
  "range_add_time": "日期", "add_time_start": "开始时间", "add_time_end": "结束时间"
};

//  ~~~~~~ 选择框, 根据实际添加 ~~~~~~
// const SelectExpressCompany = ({ value = '', onChange = e => console.log(e) }) => {
//   const [selectOptions, setSelectOptions] = useState([]);
//   useEffect(() => {
//     expressCompanyRequestGet().then(respObj => {
//         const resList = respObj.results;
//         let updateOptions = [];
//         for (let index = 0; index < resList.length; index++) {
//           const element = resList[index];
//           updateOptions.push({
//             value: element.express_company_code,
//             label: element.express_company_name
//           })
//         }
//         setSelectOptions(updateOptions);
//       }).catch(error => {
//         console.log(error);
//         setSelectOptions([]);
//       });
//   }, []);
//   return (<CustomSelect options={selectOptions} value={value} onChange={onChange}></CustomSelect>)
// };
// const SelectBoolean = (<CustomSelect options={[{ value: false, label: '否' }, { value: true, label: '是' }]} />);
const SelectRangePicker = <RangePicker />
const SelectEndTime = (<DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />);
const SelectHasPrint = (<CustomSelect options={[{ value: 0, label: '未打印' }, { value: 1, label: '已打印' }, { value: 2, label: '全部' }]} />);
const SelectSalePlatform = (<CustomSelect options={[{ value: 0, label: '未知或未输入'}, {value: 1, label: '门店'}, {value: 2, label: '淘宝'}, {value: 3, label: '小程序'}, {value: 4, label: '微信-方灵芝'}, {value: 5, label: '微信-展望眼镜'}]} />);
const SelectOrderState = (<CustomSelect options={[{ value: 0, label: '订单交易完成'}, { value: 1, label: '未付款'}, { value: 2, label: '定制中'}, { value: 3, label: '已发货'}, { value: 4, label: '售后处理中'}]} />); 
const SelectPayPlatform = (<CustomSelect options={[{ value: 0, label: '未知'}, { value: 1, label: '微信支付'}, { value: 2, label: '支付宝支付'}, { value: 3, label: '刷卡'}, { value: 4, label: '现金'}]} />);
const formKeySelectComponentsObj = {
  // 'express_company_code': <SelectExpressCompany />,
  has_print: SelectHasPrint,
  sale_platform: SelectSalePlatform,
  order_state: SelectOrderState,
  pay_platform: SelectPayPlatform,
  range_add_time: SelectRangePicker,
  add_time_start: SelectEndTime,
  add_time_end: SelectEndTime
};

//  ~~~~~~ 表单自定义操作按钮, 根据实际添加 ~~~~~~
const formKeysHandleButtonObj = (setOptCustomerModalState) => {
  return {
    customer_name: {buttonTips: '选择顾客', clickMethod: () => setOptCustomerModalState(1)},
    // group_purchase_commander_name: {buttonTips: '选择团长', clickMethod: () => setOptCustomerModalState(2)},
    pupillary_distance: {buttonTips: '选择光度瞳距', clickMethod: () => setOptCustomerModalState(3)},
  };
};

//  ~~~~~~ 表单是图片列, 根据实际添加 ~~~~~~
const formKeysIsImages = [];

//  ~~~~~~ 查询form keys, 根据实际添加 ~~~~~~
const formGetRequestParamsKeys = ['id', 'customer_name', 'sale_platform', 'order_state', 'range_add_time', 'has_print'];

//  ~~~~~~ request请求条件(有可能和 ‘查询form keys’ 不同) keys, 根据实际添加(主要参考) ~~~~~~
const requestParamsKeys = ['customer_id', 'sale_platform', 'order_state', 'add_time_start', 'add_time_end', 'has_print'];

//  ~~~~~~ 表单初始化值, 根据实际添加 ~~~~~~
const formInitialObj = {
  "id": 0, "input_staff_id": 0, "latest_modify_staff_id": 0, "customer_id": 0, "customer_name": "",
  "group_purchase_commander_id": 0, "product_title": "", "diopter_r": "", "diopter_l": "", "pupillary_distance": "",
  "receive_address": "", "products_quantity": 0, "purchase_amount": 0, "products_cost": 0, "sale_platform": 0,
  "order_state": 0, "pay_table_id": 0, "pay_platform": 0, "add_time": "", "update_time": "", "explains": "",
  "explains_customer": "", "has_print": 2, "image_cos_name1": "", "image_cos_name2": "", "image_cos_name3": "", "image_cos_name4": "", "image_cos_name5": ""
}

// ~~~~~~ 图片列名称, 根据实际修改 ~~~~~~
const imageKeyNames = ['image_cos_name', ];

// ~~~~~~ 图片总数量, 默认设置为1, 根据实际修改 ~~~~~~
const maxImagesNum = 5;

// ~~~~~~ api请求url apiLists, 根据实际修改 ~~~~~~
const apiLists = ['records-admin/v1/purchaserecord', 'records-admin/v1/purchaserecordproducts', 'records-admin/v1/purchaserecorddeliveryaddress'];

// 修改 ~~~~~~~ item keys ~~~~~~;
const ItemRendererComponent = ({ item, index, itemDetailClick }) => {
  // 必须 每一行渲染 的组件, 修改 ~~~~~~~ item keys ~~~~~~;
  console.log(item);
  return (<div>
    <ListsItem
      listsIndex={index}
      url={item.icon}
      productTitle={item.name}
      productEffects={`频道简介: ${item.front_desc}`}
      salePrice={0}
      clickDetailHandle={itemDetailClick}
    ></ListsItem>
  </div>);
};

const updateDataList = (dataList, updatedObj, method=1) => {
  // 更新数据列表 根据 updatedObj 的 id; method:1-update, 0-delete
  const updatedObjId = updatedObj.id ? updatedObj.id : 0;
  if (updatedObjId > 0) {
    const copyDataList = [].concat(dataList);
    for (let index = copyDataList.length - 1; index > -1; index--) {
      const element = copyDataList[index];
      if (element.id === updatedObjId) {
        if (method === 1) {
          // 更新
          copyDataList[index] = { ...copyDataList[index], ...updatedObj };
          console.log('update...');
          return copyDataList;
        }else if(method === 0) {
          // 删除
          copyDataList.splice(index, 1);
          console.log('delete...');
          return copyDataList
        }
      }
    }
  }
  console.log('not handle...');
  return dataList;
}

export {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysHandleButtonObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  requestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum,
  apiLists,
  ItemRendererComponent,
  updateDataList,
};

import React from 'react';

import AxiosRequest from '../apis';
import { apiLists } from './purchase_records_columns_label_config';

// PurchaseRecords 的request请求

const PurchaseRecordsRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // PurchaseRecords Get请求
  const api = apiLists[0];
  const method = 'get';
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount.length}
    ></AxiosRequest>
  );
};
PurchaseRecordsRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const PurchaseRecordsRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // PurchaseRecords post请求
  const api = [].concat(apiLists)[0];
  const method = 'post';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
PurchaseRecordsRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const PurchaseRecordsRequestPut = ({ requestCount, reqObject, responseDataHandle, id }) => {
  // PurchaseRecords put请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'put';
  const reqParams = {};
  // const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
PurchaseRecordsRequestPut.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

const PurchaseRecordsRequestDelete = ({ requestCount, responseDataHandle, id }) => {
  // PurchaseRecords delete请求
  const api = [].concat(apiLists)[0] + `/${id}`;
  const method = 'delete';
  const reqParams = {};
  const reqObject = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  );
};
PurchaseRecordsRequestDelete.defaultProps = {
  requestCount: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
  id: 0
}

export {
  PurchaseRecordsRequestGet,
  PurchaseRecordsRequestPost,
  PurchaseRecordsRequestPut,
  PurchaseRecordsRequestDelete
};

import React, { useRef, useState, useEffect } from 'react';
import { Modal, Divider, message } from 'antd';

// import StsUploadFile from '../../upload_files/sts_upload_file';
import { StsUploadImagesList } from '../upload_files/sts_upload_files_list';
import { DeliverRequestPut, DeliverRequestDelete } from './deliver_requests';
import { CustomForm, CustomSingleRowForm } from '../base_components/form_component';

import {
  formKeys,
  formDisabledKeys,
  formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysIsImages,
  formGetRequestParamsKeys,
  // formInitialObj,
  formRulesList,
  imageKeyNames,
  maxImagesNum
} from './deliver_columns_label_config';

// 编辑 Deliver

const UpdateDeliver = ({ visible, setVisible, oldObj, purchaseRecordId, customerId }) => {
  const [oldDeliverHomeImages, setOldDeliverHomeImages] = useState([]);
  const deliverImageObj = useRef({});

  const [oldDeliverImageUrl, setOldDeliverImageUrl] = useState('');
  const [oldFormObj, setOldFormObj] = useState({});

  const [initFormCount, setInitFormCount] = useState(0);

  const [putRequestCount, setPutRequestCount] = useState(0);
  const reqObject = useRef({});
  const putResponseDataHandle = (respObj) => {
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      message.success('修改成功');
      // setReloadListCount(reloadListCount + 1);
      setVisible(false);
    } else {
      message.error('网络错误...');
    }
  }

  const [deleteRequestCount, setDeleteRequestCount] = useState(0);

  const deleteDeliver = () => {
    // 删除当前对象
    console.log('Delete handle')
    // setDeleteRequestCount(deleteRequestCount + 1);
    // setReloadListCount(reloadListCount + 1);
  };

  useEffect(() => {
    initImgAndForm();   // 初始化表格和图片
  }, [oldObj]);

  const initImgAndForm = () => {
    // 初始化表格和图片
    setInitFormCount(initFormCount + 1);  // 初始化表格
    setOldFormObj({ ...oldObj });  // 给表格赋值

    // 生成图片列表
    let deliverHomeImgs = [];
    const imgKey = imageKeyNames[0];
    for (let index = 1; index < 10; index++) {
      const currentCosNameKey = imgKey + index;
      const currentUrlKey = currentCosNameKey + '_url';
      const currentCosName = oldObj[currentCosNameKey];
      const currentUrl = oldObj[currentUrlKey];
      if (typeof currentCosName === 'string' && currentCosName.length > 5) {
        deliverHomeImgs.push({
          cosNameKey: currentCosNameKey,
          cosName: currentCosName,
          urlKey: currentUrlKey,
          url: currentUrl
        });
      }
    }
    // console.log(deliverHomeImgs);
    setOldDeliverHomeImages(deliverHomeImgs);   // 更新图片列表
  }

  const deliverImageUploaded = (imagesObj) => {
    // 轮播图上传后操作
    deliverImageObj.current = imagesObj;
  }

  const onFinish = (values) => {
    // 提交表单
    let upObj = { ...values, 'purchase_record_id': purchaseRecordId, 'customer_id': customerId };
    console.log(upObj);
    // 时间转时间戳
    // let newDate = new Date(upObj.end_time);
    // upObj.end_time = parseInt(newDate.getTime());    // 时间转时间戳
    // console.log(upObj.end_time);
    reqObject.current = upObj;
    setVisible(false)
    // setPutRequestCount(putRequestCount + 1);
  }

  return (
    <>
      <Divider></Divider>
      <CustomSingleRowForm
        columns={formKeys}
        disabledKeys={formDisabledKeys}
        notShowKeys={formNotShowKeys}
        formKeyLabelObj={formKeysLabelsObj}
        formKeySelectComponentsObj={formKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={() => setVisible(false)}
        showDeleteButton={false}
        deleteHandle={deleteDeliver}
        updateFormObj={oldFormObj}
        formInitialObj={{}}
        rulesObj={formRulesList}
        initFormCount={initFormCount}
      ></CustomSingleRowForm>

      <DeliverRequestPut
        requestCount={putRequestCount}
        reqObject={reqObject.current}
        responseDataHandle={putResponseDataHandle}
        id={oldFormObj.id}></DeliverRequestPut>
      <DeliverRequestDelete
        requestCount={deleteRequestCount}
        responseDataHandle={(respObj) => setVisible(false)}
        id={oldFormObj.id}></DeliverRequestDelete>
    </>
  )
}

UpdateDeliver.defaultProps = {
  visible: false,
  setVisible: (val) => console.log(val),
  oldObj: {},
  purchaseRecordId: 0,
  customerId: 0
}

export default UpdateDeliver;

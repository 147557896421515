import { message } from 'antd';
import getLodop from './LodopFuncs';

let LODOP;

const loadPrinter = () => {
    return new Promise((resolve, reject) => {
        // 读取打印机名称列表 返回打印机名称Array window.onload = function () {}等待页面加载完成
        let hasComplete = false;    // 变量,页面是否已经加载完成
        if (typeof document !== 'undefined') {
            hasComplete = document.readyState === 'complete';
        }
        if (hasComplete) {
            LODOP = getLodop();
            if (!LODOP) {
                message.error('连接打印服务器失败...');
                reject('LODOP server connect error...');
            }else {
                const printerNum = LODOP.GET_PRINTER_COUNT();
                const printerArr = [];
                for (let index = 0; index < printerNum; index++) {
                    printerArr.push(LODOP.GET_PRINTER_NAME(index));
                }
                // console.log('SSSSS');
                resolve(printerArr);
            }
        } else {
            message.error('请等待页面加载完成, 再刷新打印机...');
            reject('LODOP server connecting...');
        }
    });
}

const hasPrinter = (printerName) => {
    // 判断打印机是否存在
    return new Promise((resolve, reject) => {
        loadPrinter.then(prints => {
            resolve(prints.includes(printerName));
        }).catch(err => {
            console.log(err);
            reject(false);
        })
    });
};

export {
    loadPrinter,
    hasPrinter
};
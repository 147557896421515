import React, { useState, useEffect, useRef } from 'react';

import { PurchaseRecordsRequestGet } from './purchase_records_requests';
import { updateDataList } from './purchase_records_columns_label_config';
import UpdatePurchaseRecords from './update_purchase_records';
import { PurchaseRecordsListComponent } from '../base_components/purchase_records_goods_delivery_address/purchase_records_list';
// 页面到底触发事件
import { LoadMoreButton } from '../base_components/load_more_button_conpoment';
import DeliverComponent from '../deliver';
import { ScrollGoBack } from '../base_components/scroll_handle/goback_origin_scroll';
// 列表编辑 页面

const judgeHasMore = resp => {
  if (Object.keys(resp).includes('next')
    && typeof resp.next === 'string'
    && resp.next.length > 5) {
    return 2;
  }
  return 1;
};

const PurchaseRecordsList = ({ reloadListCount, setReloadListCount, requestParams, purchaseRecordsChange, printerIndex, hasAllPrintCount }) => {
  const hasMore = useRef(2);
  const [hasMoreState, setHasMoreState] = useState(2);
  const [loading, setLoading] = useState(false);
  const reqParams = useRef({ page: 0 });

  const [purchaseRecordsData, setPurchaseRecordsData] = useState([]);
  const [purchaseRecordsRequestGetCount, setPurchaseRecordsRequestGetCount] = useState(0);
  const [prqGetCount, setPrqGetCount] = useState('');
  const [requesting, setRequesting] = useState(false);

  const [visible, setVisible] = useState(0);
  const [selectedObj, setSelectedObj] = useState({});

  useEffect(() => {
    // hasAllPrintCount > 0 client端全部更新为已打印
    if (hasAllPrintCount > 0) {
      const copyList = [].concat(purchaseRecordsData);
      for (let index = 0; index < copyList.length; index++) {
        copyList[index].has_print = true;
      }
      console.log(copyList);
      setPurchaseRecordsData(copyList);
      purchaseRecordsChange(copyList);   // 订单列表传给父组件
    }
  }, [hasAllPrintCount]);

  useEffect(() => {
    // 加载、更新或创建数据后 重新读取列表
    reqParams.current = { ...requestParams };
    reloadListsHandle();
  }, [reloadListCount]);

  useEffect(() => {
    // requesting 重新读取数据，解决 useState异步问题
    if (requesting && !loading && hasMore.current > 1) {
      setRequesting(false);
      loadMore();
    }
  }, [requesting, hasMore.current, loading]);

  const loadMore = () => {
    // 读取更多列表
    // console.log(hasMore.current, loading);
    // console.log('prqGetCount:', prqGetCount, purchaseRecordsRequestGetCount, '; reqParams', reqParams.current);
    if (hasMore.current > 1 && !loading) {
      hasMore.current = 1;
      setLoading(true);
      setHasMoreState(1);
      const current_page = Number.isInteger(reqParams.current.page) ? reqParams.current.page + 1 : 1;
      reqParams.current = { ...reqParams.current, page: current_page };
      console.log(reqParams.current);
      const addCount = purchaseRecordsRequestGetCount + 1;
      console.log(addCount);
      setPurchaseRecordsRequestGetCount(addCount);
      setPrqGetCount(prqGetCount + 'n');

    } else {
      console.log('Error state: hasMore is ', hasMore.current, ', loading is ', loading);
    }
  };

  const reloadListsHandle = () => {
    // 重新读取数据
    reqParams.current = { ...reqParams.current, page: 0 };
    // console.log(reqParams.current);
    setPurchaseRecordsData([]);
    hasMore.current = 2;
    setHasMoreState(2);
    setLoading(false);
    setRequesting(true);
  };

  const purchaseRecordsGetResponse = resp => {
    console.log('prqCount: ', prqGetCount);
    console.log('resp process...', resp);
    setLoading(false);
    if (Object.keys(resp).includes('code')
      && resp.code === 1000
      && Object.keys(resp).includes('results')
    ) {
      const updatedList = purchaseRecordsData.concat(resp.results);
      setPurchaseRecordsData(updatedList);
      purchaseRecordsChange(updatedList);   // 订单列表传给父组件
      hasMore.current = judgeHasMore(resp);   // 判断是否还有更多
      setHasMoreState(judgeHasMore(resp));
      console.log(purchaseRecordsRequestGetCount);
    } else {
      console.log('Error: get data...');
    }
  };

  const itemDetailClick = (purchaseRecordObj, index) => {
    // 打开详情编辑窗口
    // console.log(purchaseRecordsData[index]);
    let selObj = { ...purchaseRecordObj };
    console.log(selObj, index);
    // 日期转 moment 时间
    // selObj.end_time = moment(dateFormat(selObj.end_time), 'YYYY-MM-DD HH:mm:ss');
    setSelectedObj(selObj);
    setVisible(1);
  };

  const successPrintCurrentRecordHandle = (hasPrintRecordObj, index) => {
    // 更新打印的状态为true
    const copyRecordsList = [].concat(purchaseRecordsData);
    copyRecordsList[index] = hasPrintRecordObj;
    setPurchaseRecordsData(copyRecordsList);
  }

  const successDeletedOrUpdated = (successObj) => {
    // method: 0-delete, 1-update
    const method = successObj.method === 'update' ? 1 : 0;
    const successResult = successObj.result ? successObj.result : selectedObj;
    // successResult = {...selectedObj, ...successResult};
    // 更新操作数据列表
    const updatedDataList = updateDataList(purchaseRecordsData, successResult, method);
    console.log(updatedDataList);
    setPurchaseRecordsData(updatedDataList);
  }

  const deliverComponent = (<DeliverComponent />)

  return (
    <div>
      <ScrollGoBack visible={visible}></ScrollGoBack>

      <PurchaseRecordsListComponent
        purchaseRecordsList={purchaseRecordsData}
        loadMore={loadMore}
        hasMore={hasMoreState === 2}
        infoBottonTips="详情编辑"
        infoBottonClick={itemDetailClick}
        deliverComponent={deliverComponent}
        printerIndex={printerIndex}
        successPrintCurrentRecordHandle={successPrintCurrentRecordHandle}
      ></PurchaseRecordsListComponent>

      <PurchaseRecordsRequestGet requestCount={prqGetCount}
        reqParams={reqParams.current}
        responseDataHandle={purchaseRecordsGetResponse}
      ></PurchaseRecordsRequestGet>

      <UpdatePurchaseRecords
        visible={visible === 1}
        setVisible={setVisible}
        oldObj={selectedObj}
        successDeletedOrUpdated={successDeletedOrUpdated}
      ></UpdatePurchaseRecords>

      <LoadMoreButton hasMore={hasMore.current === 2} onClick={loadMore}></LoadMoreButton>
      {/* <ScrollBottomHandleComponent bottomHandle={loadMore} canHandle={hasMore.current === 2}></ScrollBottomHandleComponent> */}
    </div>
  );
}

PurchaseRecordsList.defaultProps = {
  reloadListCount: 1,
  setReloadListCount: (addedNum) => { console.log(addedNum); },
  requestParams: { page: 0 },
  purchaseRecordsChange: purchaseRecords => console.log(purchaseRecords),
  printerIndex: -1,
  hasAllPrintCount: 0,
}

export default PurchaseRecordsList;

import React from 'react';

import SiteLayout from '../components/site_layout';
import PurchaseRecordsComponent from '../components/purchase_records';

import SEO from "../components/seo";

const PurchaseRecordsPage = () => (
  <SiteLayout>
    <SEO title='Records'></SEO>
    <PurchaseRecordsComponent></PurchaseRecordsComponent>
  </SiteLayout>
);

export default PurchaseRecordsPage;

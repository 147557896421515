import React, { useEffect, useRef, useState } from 'react';
import { Modal, Divider, message } from 'antd';

import { CustomerOptModal } from '../modals/customer_opt';
import { DioptersOptModal } from '../modals/diopters_opt';
import { StsUploadImagesList } from '../upload_files/sts_upload_files_list';
import { PurchaseRecordsRequestPost } from './purchase_records_requests';
import { CustomForm } from '../base_components/form_component';

import {
  formKeys,
  formDisabledKeys,
  // formNotShowKeys,
  formKeysLabelsObj,
  formKeySelectComponentsObj,
  formKeysHandleButtonObj,
  // formKeysIsImages,
  // formGetRequestParamsKeys,
  formInitialObj,
  imageKeyNames,
  maxImagesNum
} from './purchase_records_columns_label_config';

// ~~~~~~ 表单不显示列, 根据实际添加 ~~~~~~
const formNotShowKeys = [ "id", "group_purchase_commander_id", "group_purchase_commander_name", "pay_table_id", "add_time", "update_time",
  "input_staff_id", "latest_modify_staff_id", "customer_id", "receive_address", "explains_customer",
  "image_cos_name1", "image_cos_name2", "image_cos_name3", "image_cos_name4", "image_cos_name5",
  "has_print"
];

// 新建 Modal

const CreatePurchaseRecords = ({ visible, setVisible, reloadListCount, setReloadListCount }) => {

  const purchaseRecordsImageObj = useRef({});
  const oldObj = useRef({createCount: 0});
  const [dioptersObj, setDioptersObj] = useState({diopter_l: "", diopter_r: "", pupillary_distance: ""});

  const [oldFormObj, setOldFormObj] = useState({});

  const [initFormCount, setInitFormCount] = useState(0);

  const [postRequestCount, setPostRequestCount] = useState(0);
  const reqObject = useRef({});

  const customerObj = useRef({});

  const [customerOptModalState, setCustomerOptModalState] = useState(0);
  const selectCustomerHandle = (cusotomerObj) => {
    // 选择顾客操作
    console.log(cusotomerObj, cusotomerObj.name);
    let showName = cusotomerObj.name;
    if (Object.keys(cusotomerObj).includes('wx_info')) {
      showName = `${showName}(${cusotomerObj.wx_info.wx_nickname})`;
    }
    customerObj.current = cusotomerObj;
    setOldFormObj({ customer_name: showName });
    setCustomerOptModalState(false);
  };
  const selectDioptersHandle = (dioptersObj) => {
    // 选择光度后操作, 保存当前光度和瞳距, 更新表单
    setDioptersObj(dioptersObj);  // 保存当前光度和瞳距
    setOldFormObj(dioptersObj);   // 更新表单
    setCustomerOptModalState(0);            // 关闭选择表单弹出框
};

  const postResponseDataHandle = (respObj) => {
    // 表单提交后的操作
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      closeModal();
      setReloadListCount(reloadListCount + 1)
    } else {
      message.error('网络错误...');
    }
  }

  useEffect(() => {
    if (visible) {
      // 初始化表格和图片
      purchaseRecordsImageObj.current = {};  // 清空图片数据
      customerObj.current = {};   // 清空选择的顾客信息
      oldObj.current = {createCount: oldObj.current.createCount + 1};  // 初始化图片
      setInitFormCount(initFormCount + 1);  // 初始化表格
      setDioptersObj({diopter_l: "", diopter_r: "", pupillary_distance: ""});   // 初始化光度
    }
  }, [visible]);

  const closeModal = () => {
    // 关闭Modal
    setVisible(false);
  }

  const purchaseRecordsImageUploaded = (imagesObj) => {
    // 图片上传后操作
    purchaseRecordsImageObj.current = { ...imagesObj };
  }

  const onFinish = (values) => {
    // 提交表单 表单没有customer_id, 提交时需要添加
    let upObj = { ...values, ...purchaseRecordsImageObj.current, customer_id: customerObj.current.id, };
    // let newDate = new Date(upObj.end_time);
    // upObj.end_time = parseInt(newDate.getTime());    // 时间转时间戳
    delete upObj.customer_name;
    // upObj.has_print = Object.keys(upObj).includes('has_print') ? upObj.has_print === 1 : false;
    reqObject.current = upObj;
    console.log(reqObject.current);
    setPostRequestCount(postRequestCount + 1);
  }

  return (
    <>
      <Modal visible={visible}
        title="PurchaseRecords 新建"
        closable={false}
        footer={null}
      >
        <div style={{ width: '100%', height: 'auto', textAlign: 'center' }}>
          <StsUploadImagesList
            oldObj={oldObj.current}
            maxImagesNum={maxImagesNum}
            imageKeyName={imageKeyNames[0]}
            onChange={purchaseRecordsImageUploaded}></StsUploadImagesList>
        </div>
        <Divider></Divider>

        <CustomForm
          columns={formKeys}
          disabledKeys={formDisabledKeys}
          notShowKeys={formNotShowKeys}
          formKeyLabelObj={formKeysLabelsObj}
          formKeySelectComponentsObj={formKeySelectComponentsObj}
          formKeysItemButtonObj={formKeysHandleButtonObj(setCustomerOptModalState)}
          onFinish={onFinish}
          cancelHandle={closeModal}
          showDeleteButton={false}
          updateFormObj={oldFormObj}
          formInitialObj={formInitialObj}
          initFormCount={initFormCount}
        ></CustomForm>

        <PurchaseRecordsRequestPost
          requestCount={postRequestCount}
          reqObject={reqObject.current}
          responseDataHandle={postResponseDataHandle}></PurchaseRecordsRequestPost>

        <CustomerOptModal
          modalState={customerOptModalState === 1}
          setModalState={setCustomerOptModalState}
          selectCustomerHandle={selectCustomerHandle}></CustomerOptModal>

        <DioptersOptModal
          sourceDiopterObj={dioptersObj}
          modalState={customerOptModalState === 3}
          setModalState={setCustomerOptModalState}
          selectHandle={selectDioptersHandle}></DioptersOptModal>
      </Modal>
    </>
  )
}

CreatePurchaseRecords.defaultProps = {
  visible: false,
  setVisible: (val) => console.log(val),
  reloadListCount: 0,
  setReloadListCount: (booleanVal) => console.log(booleanVal)
}

export default CreatePurchaseRecords;

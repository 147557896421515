import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import './components_css/page_is_loading.css';

const PageIsLoading = ({ spinning }) => {
  // 全局读取中
  const [showLoading, setShowLoading] = useState(false);

  const divStyle = {
    display: showLoading ? 'block' : 'none',
  }

  useEffect(() => {
    // spinning 更新后 更新divStyle
    setShowLoading(spinning);
  }, [spinning]);

  return (
    <div className="overall-loading" style={divStyle}>
      <Spin spinning={spinning} size="large" />
    </div>
  )
};

PageIsLoading.defaultProps = {
  spinning: false
}

export default PageIsLoading;
import React from 'react';
import { Modal } from 'antd';
import { DoubleEyeSelectDiopter } from '../base_components/diopter_component/select_diopter';

// 选择光度模态框

const DioptersOptModal = ({ sourceDiopterObj, modalState, setModalState, selectHandle }) => {
    // 选择光度 modal
    return (
        <>
            <Modal
                visible={modalState}
                title="选择光度"
                closable={false}
                footer={null}
            >
                <DoubleEyeSelectDiopter
                    sourceDiopterObj={sourceDiopterObj}
                    selectedHandle={selectHandle}
                    cancelHandle={() => setModalState(false)}
                ></DoubleEyeSelectDiopter>
            </Modal>
        </>
    );
};
DioptersOptModal.defaultProps = {
    sourceDiopterObj: {diopter_l: "", diopter_r: "", pupillary_distance: ""},
    modalState: false,
    setModalState: booleanValue => console.log(booleanValue),
    selectCustomerHandle: (customerObj) => { console.log(customerObj); },
    clickCreatedCustomerHandle: (createdCustomerResponseObj) => { console.log(createdCustomerResponseObj) },
};

export { DioptersOptModal };

import React from 'react';
import { pca, pcaa } from 'area-data'; // v3 or higher
// import 'react-area-linkage/dist/index.css'; // v2 or higher
import { AreaSelect, AreaCascader } from 'react-area-linkage';

// area-data install: npm install area-data --save

const ChinaAreaSelect = ({initAreaList, selectedAreaHandle}) => {
    // 中国地区选择
    const selectedChange = (selectedArea) => {
        console.log(selectedArea);
        selectedAreaHandle(selectedArea)
    }
    return (
        <AreaSelect onChange={selectedChange} level={2} data={pcaa} defaultArea={initAreaList} />
    )
}
ChinaAreaSelect.defaultProps = {
    initAreaObj: [],
    selectedAreaHandle: selectedArea => console.log(selectedArea),
}

export { ChinaAreaSelect };
